import {useNavigate} from "react-router-dom";
// Import everything needed to use the `useQuery` hook
import {useQuery} from '@apollo/client';
import {GET_ALBUM_NAMES} from '../graphql-schema'
import {Button, ButtonGroup} from "@mui/material";

const ListAlbums = () => {
    const navigate = useNavigate();
    const viewAlbum = (albumName) => {
        navigate(`ViewAlbum/${albumName}`)
    }
    const {loading, error, data} = useQuery(GET_ALBUM_NAMES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;

    return (
        <div>
            <h2>Albums</h2>
            {data.getAlbumNames.length > 0 ? <p>Click on the album buttons below to view.</p> :
                <p>There are no albums to view.</p>}
            <ButtonGroup size="large" aria-label="large button group">
                {data.getAlbumNames.map((albumName, index) =>
                    <Button key={index} variant="outlined" onClick={() => viewAlbum(albumName)}>{albumName}</Button>
                )}
            </ButtonGroup>
        </div>);
}

export default ListAlbums
