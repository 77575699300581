import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {ImageListItem} from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ImageWithModal = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <ImageListItem key={props.index}>
                <img
                    src={`${props.url}`}
                    alt={props.alt}
                    loading="lazy"
                    onClick={handleOpen}
                    style={{cursor: 'pointer'}}
                />
            </ImageListItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={props.url} alt={props.alt}/>
                </Box>
            </Modal>
        </>
    );
}

export default ImageWithModal
