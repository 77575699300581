import {Amplify, Auth} from 'aws-amplify';
import {awsExports} from './aws-exports';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import Authenticated from './components/Authenticated';
import {ApolloClient, InMemoryCache, ApolloProvider, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: awsExports.GRAPHQL_API_URL,
});

const authLink = setContext(async (_, {headers}) => {
    // get the authentication token from local storage if it exists
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

// Configure Amplify in index file or root file
Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
        oauth: {
            domain: 'stevenwleung.auth.ap-southeast-2.amazoncognito.com',
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: `${window.location.origin}`,
            redirectSignOut: `${window.location.origin}`,
            responseType: 'code'
        }
    }
})

function App() {
    return (
        <Authenticator socialProviders={['google']} hideSignUp={true}>
            {({signOut, user}) => (
                <ApolloProvider client={client}>
                    <Authenticated signOutClicked={signOut} user={user}/>
                </ApolloProvider>
            )}
        </Authenticator>
    );
}


export default App;
