import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "../pages/Layout";
import ListAlbums from "../pages/ListAlbums";
import NoPage from "../pages/NoPage";
import ViewAlbum from "../pages/ViewAlbum";
import Header from "./Header";

const Authenticated = (props) => {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/"
                           element={<Layout><Header user={props.user} signOutClicked={props.signOutClicked}/></Layout>}>
                        <Route index element={<ListAlbums/>}/>
                        <Route path="ListAlbums" element={<ListAlbums/>}/>
                        <Route path="ViewAlbum/:id" element={<ViewAlbum/>}/>
                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Authenticated
