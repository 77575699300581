import {useParams} from "react-router-dom";
import ListAlbumButton from "../components/ListAlbumButton";
import {useQuery} from '@apollo/client';
import {GET_ALBUM} from '../graphql-schema'
import {ImageList} from "@mui/material";
import ImageWithModal from "../components/ImageWithModal";


const ViewAlbum = () => {
    let {id} = useParams();

    const {loading, error, data} = useQuery(GET_ALBUM, {
        variables: {albumName: id},
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;

    return (
        <div>
            <ListAlbumButton/>
            <h2>
                Album: {id}
            </h2>
            {data.getAlbumByName.photos.length > 0 ? <p>The following photos are present.</p> :
                <p>There are no photos in this album.</p>}
            <div>
                <ImageList sx={{width: 500, height: 450}} cols={3} rowHeight={164}>
                    {data.getAlbumByName.photos.map((item, index) => (
                        <ImageWithModal key={index} url={item.url} alt={item.key}/>
                    ))}
                </ImageList>
            </div>
            <h2>
                End of Album: {id}
            </h2>
            <ListAlbumButton/>
        </div>
    );
}

export default ViewAlbum;
