import {Button} from "@mui/material";

const Header = (props) => {
    return (<header>
        <p>Welcome {props.user.attributes.given_name}</p>
        <Button variant="outlined" size="large" onClick={props.signOutClicked}>Sign out</Button>
        <h1>Photo Album Viewer</h1>
    </header>);
}

export default Header;
