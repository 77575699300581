import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";

const ListAlbumButton = () => {

    const navigate = useNavigate();
    const listAlbumsClicked = () => {
        navigate('/')
    }

    return (<div>
        <Button variant="outlined" size="large" onClick={listAlbumsClicked}>
            Back To Albums
        </Button>
    </div>);
}

export default ListAlbumButton
