import {gql} from "@apollo/client";

export const GET_ALBUM_NAMES = gql`query getAlbumByName {
    getAlbumNames
}`

export const GET_ALBUM = gql`query getAlbumByName ($albumName: String) {
    getAlbumByName(albumName:$albumName){
        name
        photos{
            key
            url
        }
    }
}`
