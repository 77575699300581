import {Outlet} from "react-router-dom";
//import Navigation from "../components/Navigation";

const Layout = (props) => {
    return (
        <>
            {/*<Navigation/>*/}
            {props.children}
            <Outlet/>
        </>
    )
};

export default Layout;
